/* App.css */

/* Import Google Font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* Apply Font to Body */
body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  background-color: var(--background-gray);
  color: var(--text-gray);
}

.text-xl {
  font-size: 1.25rem;
  font-family: 'Poppins', sans-serif; /* Apply Font to Specific Class */
}

.text-5xl {
  font-size: 3rem;
  font-family: 'Poppins', sans-serif; /* Apply Font to Specific Class */
}
:root {
  --dark: #ff6f61;
  --primary: #ff6f61;
  --white: #ffffff;
  --text-gray: #9b9b9b;
  --background-gray: #1e1e1e;
  --hover-primary: #ff8b74;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  background-color: var(--background-gray);
  color: var(--text-gray);
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: var(--hover-primary);
}

button, .button {
  background-color: var(--primary);
  color: var(--white);
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

button:hover, .button:hover {
  background-color: var(--hover-primary);
}

.header-icons a {
  margin-right: 1rem;
  color: var(--white);
  font-size: 1.5rem;
}

.header-icons a:hover {
  color: var(--primary);
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.h-screen {
  height: 100vh;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.text-center {
  text-align: center;
}

.text-5xl {
  font-size: 3rem;
}

.font-bold {
  font-weight: 700;
}

.text-white {
  color: var(--white);
}

.text-xl {
  font-size: 1.25rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.bg-dark {
  background-color: var(--dark);
}

.rounded {
  border-radius: 0.25rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.text-3xl {
  font-size: 1.875rem;
}

.font-medium {
  font-weight: 500;
}

.fixed {
  position: fixed;
}

.w-full {
  width: 100%;
}

.z-10 {
  z-index: 10;
}

.top-0 {
  top: 0;
}

.shadow-md {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.p-5 {
  padding: 1.25rem;
}

.flex-col {
  flex-direction: column;
}

.md\:flex-row {
  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.items-center {
  align-items: center;
}

.title-font {
  font-family: 'Merriweather', serif;
}

.mb-4 {
  margin-bottom: 1rem;
}

.md\:mb-0 {
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}

.ml-3 {
  margin-left: 0.75rem;
}

.md\:ml-auto {
  @media (min-width: 768px) {
    margin-left: auto;
  }
}

.mr-5 {
  margin-right: 1.25rem;
}

.hover\:text-primary:hover {
  color: var(--primary);
}
/* Add any necessary styles here */
#resume {
  background-color: var(--background-gray);
  color: var(--text-gray);
}

#resume h2 {
  color: var(--white);
}

#resume h3, #resume h4 {
  color: var(--white);
}

#resume ul {
  list-style-type: none;
  padding-left: 0;
}

#resume ul.list-disc {
  list-style-type: disc;
}

#resume .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
}

#resume .text-white {
  color: var(--white);
}
/* App.css */

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-up {
  opacity: 0;
  animation: fadeInUp 1s ease-out forwards;
}



